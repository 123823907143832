import BrowserLogger from 'alife-logger';
import { getEnv } from '../utils';
declare const window: any;

export const armsInit = () => {
    // 只在正式环境上报
    if (!getEnv()) {
        window.__bl = BrowserLogger.singleton({
            pid: 'a644wg37bd@1e8476c6739d8ab',
            appType: 'web',
            imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?',
            sendResource: true,
            enableLinkTrace: true,
            behavior: true,
            ignore: {
                ignoreErrors: [/^Script error\.?$/, /^Network Error$/],
            },
        });
    }
};
